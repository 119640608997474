.table-skrocona td{
    text-align: left;
}

.table-skrocona tr td:nth-child(3){
    text-align: right;
}
.table-skrocona th {
    text-align: center;
}

.table-skrocona {
    width: 100%;
}


.wyniki-puchar-PCZPSP th, .wyniki-puchar-PCZPSP td, .wyniki-zawody-PCZPSP th, .wyniki-zawody-PCZPSP td {
    text-align: center;
}

.wyniki-puchar-PCZPSP td.zawodnik, .wyniki-zawody-PCZPSP td.zawodnik {
    text-align: left;
}

.wyniki-puchar-KCZKSP th, .wyniki-puchar-KCZKSP td, .wyniki-zawody-KCZKSP th, .wyniki-zawody-KCZKSP td {
    text-align: center;
}

.wyniki-puchar-KCZKSP td.zawodnik, .wyniki-zawody-KCZKSP td.zawodnik {
    text-align: left;
}

.wyniki-puchar-TRAP th, .wyniki-puchar-TRAP td, .wyniki-zawody-TRAP th, .wyniki-zawody-TRAP td {
    text-align: center;
}

.wyniki-puchar-TRAP td.zawodnik, .wyniki-zawody-TRAP td.zawodnik {
    text-align: left;
}

.wyniki-puchar-DWUBOJ th, .wyniki-puchar-DWUBOJ td, .wyniki-zawody-DWUBOJ th, .wyniki-zawody-DWUBOJ td {
    text-align: center;
}

.wyniki-puchar-DWUBOJ td.zawodnik, .wyniki-zawody-DWUBOJ td.zawodnik {
    text-align: left;
}

.wyniki-puchar-WIELOBOJ th, .wyniki-puchar-WIELOBOJ td, .wyniki-zawody-WIELOBOJ th, .wyniki-zawody-WIELOBOJ td {
    text-align: center;
}

.wyniki-puchar-WIELOBOJ td.zawodnik, .wyniki-zawody-WIELOBOJ td.zawodnik {
    text-align: left;
}

.ImieNazwisko {
    font-size:11px;
    font-weight: bold;
}

.Klub {
    font-size: 9px;
    font-style: italic;
}

.center_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nazwa_konkurencji {
    font-weight: bold;
}

.licpk {
    font-size: 10px;
}